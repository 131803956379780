import Vue from "vue";
import VueRouter from "vue-router";
import { isUserLoggedIn } from "@/auth/utils";
import dashboard from "./routes/dashboard";
import invite from "./routes/admin/invite";
import customVedio from "./routes/admin/customVedio";
import admin from "./routes/admin";
import AssetsManager from "./routes/AssetsManager";
import pages from "./routes/pages";
import pushManager from "./routes/push-manager";
import keywordManager from "./routes/keyword-manager";
import settings from "./routes/settings";
import adsManager from "./routes/ads-manager";
import marketManager from "./routes/market-manager";
import marketplace from "./routes/marketplace";
import store from "@/store";
import topCharts from "./routes/top-charts";
import ability from "@/libs/acl/ability";

Vue.use(VueRouter);
let name;
if(localStorage.getItem('userData')){
   name =
  JSON.parse(localStorage.getItem("userData")).role === "user"
    ? "dashboard"
    : JSON.parse(localStorage.getItem("userData")).role === "admin"
    ? "admin-dash"
    : "error-404";
}

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    { path: "/", redirect: { name } },
    ...dashboard,
    ...pushManager,
    ...keywordManager,
    ...settings,
    ...adsManager,
    ...marketManager,
    ...invite,
    ...admin,
    ...topCharts,
    ...pages,
    ...customVedio,
    ...marketplace,
    ...AssetsManager,
    {
      path: "*",
      redirect: "error-404",
    },
  ],
});

router.beforeEach((to, _, next) => {
  if (
    to.path === "/login" ||
    to.path == "/error-404" ||
    to.path === "/success-register" ||
    to.path === "/invite-mail" ||
    to.path === "/register" ||
    to.path === "/signup/verify-email"
  ) {
    return next();
  }
  const isLoggedIn = isUserLoggedIn();
  if (isLoggedIn) {
    ability.update([
      {
        action: "manage",
        subject: "all",
      },
    ]);
    return next();
  }
  if (!store.state.sent && !to.query.token) return next({ name: "auth-login" });
  else return next();
});

// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  const appLoading = document.querySelector(".loading-container");
  if (appLoading) {
    appLoading.remove();
  }
});

export default router;
