// import adsGenerator from './ads-generator'
import webSiteCampaign from "./website-campaign";
import pushCampaigns from "./push-campaign";
import domain from "./domain";
import config from "./config";
import editor from "./editor-preview";


const data = [
  ...webSiteCampaign,
  ...pushCampaigns,
  ...domain,
  ...config,
  ...editor
];

export default data;
