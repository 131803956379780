import axios from "@axios";
import router from "@/router";
import { removeLocalStorageItems } from "@/helper/helpers-funcs.js";
export default {
  namespaced: true,
  state: {
    //?---------------------------- Settings state ----------------------------?//
    accounts: [],
    accountID: null,
    apps: [],
    appID: null,

    notifs: [],
    notifID: null,
    sentNotifsArray: [],
    programmedNotifsArray: [],
    notSentNotifsArray: [],
    notProgrammedNotifsArray: [],
  },
  getters: {},
  mutations: {
    //?---------------------------- Settings Mutations ----------------------------?//
    SETACCOUNTID(state, id) {
      state.accountID = id;
    },
    SETAPPID(state, id) {
      state.appID = id;
    },
    SETNOTIFID(state, id) {
      state.notifID = id;
    },
    UPDATE_APPS(state, val) {
      state.apps = val;
    },
    UPDATE_ACCOUNTS(state, val) {
      state.accounts = val;
    },
    UPDATE_NOTIFS(state, val) {
      state.notifs = val;
    },
    //* ----------------- Main Script Mutations ----------------- *//
    SETMAINSCRIPT(state, value) {
      state.mainScript = value;
    },
    //? ----------------- Main HTML Mutations ----------------- ?//
    SETMAINHTML(state, value) {
      state.mainHtml = value;
    },
    //! ----------------- Template Model Mutations ----------------- !//
    SETTEMPLATEMODELS(state, value) {
      state.templateModels = value;
    },
    SETTEMPLATEMODELID(state, id) {
      state.templateModelID = id;
    },
    //TODO ------------------ Domain Mutations ---------------------- TODO//
    UPDATE_DOMAINS(state, val) {
      state.domains = val;
    },
    UPDATE_NOTREFDOMAINS(state, val) {
      state.notRefDomains = val;
    },
    SETDOMAINID(state, id) {
      state.domainID = id;
    },
    //* ----------------- Campaigns Mutations ----------------- *//
    UPDATE_CAMPAIGNS(state, val) {
      state.campaigns = val;
    },
    UPDATE_24H_CAMPAIGNS(state, val) {
      state._24Hcampaigns = val;
    },
    SETCAMPAIGNID(state, id) {
      state.campaignID = id;
    },
  },
  actions: {
    //?---------------------------- Settings Actions ----------------------------?//
    getAllAccounts({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .post("/get-accounts", {
            user_token: JSON.parse(localStorage.getItem("accessToken")),
          })
          .then((response) => {
            if (response.status == 200) {
              commit("UPDATE_ACCOUNTS", response.data[0]);
              if (response.data.status == "Unauthorized") {
                removeLocalStorageItems();
                router.push({ name: "auth-login" });
              }
              resolve(response);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    //* ----------------- Main Script ----------------- *//
    getMainScirpt({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/get-main-script")
          .then((response) => {
            if (response.status == 200) {
              commit("SETMAINSCRIPT", response.data["main-script"]);
              if (response.status == "Unauthorized") {
                removeLocalStorageItems();
                router.push({ name: "auth-login" });
              }
              resolve(response);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getAllApps({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .post("/get-apps", {
            user_token: JSON.parse(localStorage.getItem("accessToken")),
          })
          .then((response) => {
            if (response.status == 200) {
              // commit('UPDATE_DOMAINS', response.data[0])
              commit("UPDATE_APPS", response.data[0]);
              if (response.data.status == "Unauthorized") {
                removeLocalStorageItems();
                router.push({ name: "auth-login" });
              }
              resolve(response);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    //? ----------------- Main HTML ----------------- ?//
    getMainHTML({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/get-main-html")
          .then((response) => {
            if (response.status == 200) {
              commit("SETMAINHTML", response.data["main-html"]);
              if (response.data.status == "Unauthorized") {
                removeLocalStorageItems();
                router.push({ name: "auth-login" });
              }
              resolve(response);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getAppsByAccount({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/getAppsByAccount", {
            account_id: this.accSelected,
          })
          .then((response) => {
            if (response.status == 200) {
              commit("UPDATE_DOMAINS", response.data[0]);
              if (response.data.status == "Unauthorized") {
                removeLocalStorageItems();
                router.push({ name: "auth-login" });
              }
              resolve(response);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    //! ----------------- Template Model ----------------- !//
    setTemplateModelId({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit("SETTEMPLATEMODELID", id);
        resolve();
      });
    },
    getAllTemplateModels({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/get-template-models")
          .then((response) => {
            if (response.status == 200) {
              commit("SETTEMPLATEMODELS", response.data["template-models"]);
              if (response.data.status == "Unauthorized") {
                removeLocalStorageItems();
                router.push({ name: "auth-login" });
              }
              resolve(response);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getNotifsByAccount({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/getNotifsByAccount", {
            account_id: this.accSelected,
          })
          .then((response) => {
            if (response.status == 200) {
              commit("UPDATE_NOTIFS", response.data[0]);
              if (response.data.status == "Unauthorized") {
                removeLocalStorageItems();
                router.push({ name: "auth-login" });
              }
              resolve(response);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    //TODO ------------------ Domain ---------------------- TODO//
    getAllDomains({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/get-all-domains")
          .then((response) => {
            if (response.status == 200) {
              commit("UPDATE_DOMAINS", response.data[0]);
              if (response.data.status == "Unauthorized") {
                removeLocalStorageItems();
                router.push({ name: "auth-login" });
              }
              resolve(response);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getAllNotifs({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/showAllNotifications")
          .then((response) => {
            if (response.status == 200) {
              commit("UPDATE_NOTIFS", response.data[0]);
              if (response.data.status == "Unauthorized") {
                removeLocalStorageItems();
                router.push({ name: "auth-login" });
              }
              resolve(response);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getAllNotReferredDomains({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/get-all-not-referred-domains")
          .then((response) => {
            if (response.status == 200) {
              commit("UPDATE_NOTREFDOMAINS", response.data[0]);
              if (response.data.status == "Unauthorized") {
                removeLocalStorageItems();
                router.push({ name: "auth-login" });
              }
              resolve(response);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    setAccountId({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit("SETACCOUNTID", id);
        resolve();
      });
    },
    setAppId({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit("SETAPPID", id);
        resolve();
      });
    },
    setNotifId({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit("SETNOTIFID", id);
        resolve();
      });
    },

    setDomainId({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit("SETDOMAINID", id);
        resolve();
      });
    },
    //* ----------------- Campaigns ----------------- *//
    getAllCampaigns({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/get-all-campaigns")
          .then((response) => {
            if (response.status == 200) {
              commit("UPDATE_CAMPAIGNS", response.data.campaigns);
              if (response.data.status == "Unauthorized") {
                removeLocalStorageItems();
                router.push({ name: "auth-login" });
              }
              resolve(response);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getAll24HCampaigns({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/get-all-24h-campaigns")
          .then((response) => {
            if (response.status == 200) {
              commit("UPDATE_24H_CAMPAIGNS", response.data.campaigns);
              if (response.data.status == "Unauthorized") {
                removeLocalStorageItems();
                router.push({ name: "auth-login" });
              }
              resolve(response);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    setCampaignId({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit("SETCAMPAIGNID", id);
        resolve();
      });
    },
  },
};
