export default [
  {
    path: "/editor/preview-content",
    name: "editor-preview-content",
    component: () => import("@/views/ads-manager/dependencies/editor/previewEditorContent.vue"),
    meta: {
      layout: "full",
      resource: "ACL",
      action: "all",
    },
  },
];
