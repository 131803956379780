export default [
  {
    path: "/Push-campaigns",
    name: "push-campaigns",
    component: () =>
      import("@/views/ads-manager/campaign/Push/index.vue"),
    meta: {
      resource: "ACL",
      action: "all",
    },
  },

  {
    path: "/Push-campaigns/generator",
    name: "Push-campaigns-generator",
    component: () =>
      import(
        "@/views/ads-manager/campaign/Push/create.vue"
      ),
    meta: {
      resource: "ACL",
      action: "all",
    },
  },
  {
    path: "/Push-campaigns/:id/edit",
    name: "edit-push-campaign",
    component: () =>
      import("@/views/ads-manager/campaign/Push/update.vue"),
    meta: {
      resource: "ACL",
      action: "all",
    },
  },
];
