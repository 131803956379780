module.exports = {
  removeLocalStorageItems: () => {
    // Remove accessToken from localStorage
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userAccess");
    // Remove userData from localStorage
    localStorage.removeItem("userData");
  },
  authHeaderToken() {
    const token = JSON.parse(localStorage.getItem("userAccess")).token;
    const header = {
      headers: {
        Authorization: token,
      },
    };
    return header;
  },
  secondFunc: () => {},
  composeHeaderImage: (link, title) => {
    return `<div class="col-12 p-0 m-0">
    <div class="card p-0 m-0 border-0">
        <img src="${link}" class="card-img" alt="${title}">
    </div>
</div>`;
  },
  composeShortDescription: (text) => {
    return `<div class="col-12 p-5 pt-0 m-0">
    <p class="text-break text-end lh-lg">${text}</p>
</div>`;
  },
  composeIconImageAndTitle: (_link, _title) => {
    return `<div class="card mt-3 border-0">
    <div class="row">
        <div class="col-4">
            <img src="${_link}" class="img-fluid rounded-circle" alt="${_title}">
        </div>
        <div class="col-8">
            <div class="card-body">
                <h5 class="card-title text-end fw-bold">${_title}</h5>
                <p class="card-text">
                    4.7
                    <span class="fa-solid fa-star" style="color: #fdd600;"></apan>
                    <span class="fa-solid fa-star" style="color: #fdd600;"></apan>
                    <span class="fa-solid fa-star" style="color: #fdd600;"></apan>
                    <span class="fa-solid fa-star" style="color: #fdd600;"></apan>
                    <span class="fa-solid fa-star-half-stroke" style="color: #fdd600;"></span>
                </p>
            </div>
        </div>
    </div>
</div>`;
  },
  composeContainer: (HeaderImage, IconImageAndTitle, ShortDescription) => {
    return `<div class="container-fluid p-0 m-0" style="direction:rtl;">
    <div class="row p-0 m-0 border-0">
        ${HeaderImage}
        ${IconImageAndTitle}
        ${ShortDescription}
    </div>
</div>`;
  },
};
