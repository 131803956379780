import Vue from "vue";
import { ToastPlugin, ModalPlugin } from "bootstrap-vue";
import VueCompositionApi from "@vue/composition-api";

import Vuesax from "vuesax";
import i18n from "@/libs/i18n";
import router from "./router";
import store from "./store";
import App from "./App.vue";

// ######### Global variables #########
let ImagesURL = "https://app.appsmanager.co/backend/public/storage/img/";
// let ImagesURL = "https://dev.appsmanager.co/backend/public/storage/img/";
// let ImagesURL = "http://localhost:8000/storage/img/";
Vue.prototype.$ImagesURL = ImagesURL;

let ScriptsURL = "https://app.appsmanager.co/backend/public/storage/scripts/";
// let ScriptsURL = "https://dev.appsmanager.co/backend/public/storage/scripts/";
// let ScriptsURL = "http://localhost:8000/storage/scripts/";
Vue.prototype.$ScriptsURL = ScriptsURL;
Vue.prototype.$appVersion = "3.12.11";
// var d = new Date();

// var date = d.getUTCDate();
// var month = d.getUTCMonth(); // Since getMonth() returns month from 0-11 not 1-12
// var stringMonth = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
// var year = d.getUTCFullYear();
// Vue.prototype.$appVersionDate = date + "-" + stringMonth[month] + "-" + year;
Vue.prototype.$appVersionDate = "05-10-2023";

// Packages
// require lib
import VueCodemirror from "vue-codemirror";

// require styles
import "codemirror/lib/codemirror.css";

// require more codemirror resource...

// you can set default global options and events when use
Vue.use(VueCodemirror, {
  // options: { theme: 'dracula' },
  //   events: ['scroll', ...]
});
// Vuesax

import "vuesax/dist/vuesax.css";

// Global Components
import "./global-components";

// 3rd party plugins
// import "@axios";
import "@/libs/acl";
// import "@/libs/portal-vue";
import "@/libs/clipboard";
import "@/libs/toastification";
// import "@/libs/sweet-alerts";
// import "@/libs/vue-select";
// import "@/libs/tour";

// Vuesax styles
Vue.use(Vuesax, {
  // options here
});

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);

// Composition API
Vue.use(VueCompositionApi);

//Vue-ApexCharts
import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);
// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require("@core/assets/fonts/feather/iconfont.css"); // For form-wizard

// import core styles
require("@core/scss/core.scss");

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (component) => component(App),
}).$mount("#app");
