export default [
  {
    path: "/configuration/mainscript",
    name: "configuration-mainscript",
    component: () =>
      import("@/views/ads-manager/config/main-script/MainScript.vue"),
    meta: {
      resource: "ACL",
      action: "all",
    },
  },
  {
    path: "/configuration/template-models",
    name: "configuration-template-models",
    component: () =>
      import("@/views/ads-manager/config/template-models/TemplateModels.vue"),
    meta: {
      resource: "ACL",
      action: "all",
    },
  },
  {
    path: "/configuration/template-models/generator",
    name: "configuration-template-models-generator",
    component: () =>
      import(
        "@/views/ads-manager/config/template-models/TemplateModelsGenerator.vue"
      ),
    meta: {
      resource: "ACL",
      action: "all",
    },
  },
  {
    path: "/configuration/template-models/edit",
    name: "configuration-template-models-edit",
    component: () =>
      import(
        "@/views/ads-manager/config/template-models/EditTemplateModels.vue"
      ),
    meta: {
      resource: "ACL",
      action: "all",
    },
  },
  {
    path: "/configuration/main-basehtml",
    name: "configuration-main-basehtml",
    component: () =>
      import("@/views/ads-manager/config/main-html/MainHtml.vue"),
    meta: {
      resource: "ACL",
      action: "all",
    },
  },
];
