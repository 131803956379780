import Vue from "vue";

// axios
import axios from "axios";

// Global variables
let theBaseURL = "https://app.appsmanager.co/backend/public/api/";
// let theBaseURL = "https://dev.appsmanager.co/backend/public/api/";
// let theBaseURL = "http://127.0.0.1:8000/api/";

const axiosIns = axios.create({
  baseURL: theBaseURL,
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
  headers: {
    Authorization: "Bearer " + localStorage.getItem("accessToken"),
    "Content-Type": "multipart/form-data",
    "Content-Type": "application/json",
  },
});

Vue.prototype.$http = axiosIns;

export default axiosIns;
