export default [
  {
    path: "/push-dashboard",
    name: "push-dashboard",
    component: () =>
      import("@/views/push-manager/dashboard-reporting.vue"),
    meta: {
      resource: "ACL",
      action: "all",
    },
  },
  {
    path: "/notifications",
    name: "notifications",
    component: () =>
      import("@/views/push-manager/notificationsManagement.vue"),
    meta: {
      resource: "ACL",
      action: "all",
    },
  },
  {
    path: "/notifications/creation",
    name: "notifications-creation",
    component: () =>
      import("@/views/push-manager/notificationsGenerator.vue"),
    meta: {
      resource: "ACL",
      action: "all",
    },
  },
  {
    path: "/notifications/edit",
    name: "notifications-edit",
    component: () =>
      import("@/views/push-manager/editNotifications.vue"),
    meta: {
      resource: "ACL",
      action: "all",
    },
  },
  {
    path: "/notifications-programming",
    name: "notifications-programming",
    component: () =>
      import(
        "@/views/push-manager/notificationsProgramming.vue"
      ),
    meta: {
      resource: "ACL",
      action: "all",
    },
  },
  {
    path: "/notifications-sending",
    name: "notifications-sending",
    component: () =>
      import(
        "@/views/push-manager/notificationsSending.vue"
      ),
    meta: {
      resource: "ACL",
      action: "all",
    },
  },
];
