export default [
  {
    path: "/domains",
    name: "domains",
    component: () => import("@/views/ads-manager/domain/index.vue"),
    meta: {
      resource: "ACL",
      action: "all",
    },
  },
  {
    path: "/domains/creation",
    name: "domains-creation",
    component: () => import("@/views/ads-manager/domain/create.vue"),
    meta: {
      resource: "ACL",
      action: "all",
    },
  },
  {
    path: "/domains/edit",
    name: "domains-edit",
    component: () => import("@/views/ads-manager/domain/update.vue"),
    meta: {
      resource: "ACL",
      action: "all",
    },
  },
];
