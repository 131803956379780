export default [
  {
    path: "/assets/source-list",
    name: "am-assets-source",
    component: () => import("@/views/AssetsManager/source/ListSource.vue"),
  },
  {
    path: "/assets/source/create",
    name: "am-assets-new-source",
    component: () => import("@/views/AssetsManager/source/newSource.vue"),
  },
  {
    path: "/assets/source/:id/update",
    name: "am-assets-update-source",
    component: () => import("@/views/AssetsManager/source/UpdateSource.vue"),
  },
 
  {
    path: "/assets/data-list",
    name: "am-assets-data",
    component: () => import("@/views/AssetsManager/data/ListData.vue"),
  },
  {
    path: "/assets/data/create",
    name: "am-data-new-data",
    component: () => import("@/views/AssetsManager/data/newData.vue"),
  },
  {
    path: "/assets/data/:id/update",
    name: "am-assets-update-data",
    component: () => import("@/views/AssetsManager/data/UpdateData.vue"),
  },

];
