export default [
  {
    path: "/credentials",
    name: "credentials",
    component: () => import("@/views/push-manager/config/credentials.vue"),
    meta: {
      resource: "ACL",
      action: "all",
    },
  },
  // {
  //   path: "/config-time-notif",
  //   name: "config-time-notif",
  //   component: () => import("@/views/dashboard/credentials/notifTime.vue"),
  //   meta: {
  //     resource: "ACL",
  //     action: "all",
  //   },
  // }
];
