export default [
  {
    path: "/myprofile/accounts",
    name: "accounts",
    component: () =>
      import("@/views/settings/accountsManagement.vue"),
    meta: {
      resource: "ACL",
      action: "all",
    },
  },
  {
    path: "/myprofile/accounts/creation",
    name: "accounts-creation",
    component: () => import("@/views/settings/accountsGenerator.vue"),
    meta: {
      resource: "ACL",
      action: "all",
    },
  },
  {
    path: "/myprofile/account/:id/apps/creation",
    name: "account-app-creation",
    component: () =>
      import("@/views/settings/accountsAppsGenerator.vue"),
    meta: {
      resource: "ACL",
      action: "all",
    },
  },
  {
    path: "/myprofile/accounts/edit",
    name: "accounts-edit",
    component: () => import("@/views/settings/editAccounts.vue"),
    meta: {
      resource: "ACL",
      action: "all",
    },
  },
  {
    path: "/statistics/account/:account_name,:account_id",
    name: "statistics-account",
    component: () => import("@/views/settings/analytics/accountStatistics.vue"),
    meta: {
      resource: "ACL",
      action: "all",
    },
  },
];
